<template>
    <div>
        <v-container fluid>
            <div style="align-content: center; width: auto; height: auto; text-align: center; margin: 25px;">
                <img alt src="@/assets/Seed_Web_Icons/seed logo.png" width="200" height="200" />
            </div>
            <div style="align-content: center; width: auto; height: 50; text-align: center;">
                <a href="https://apps.apple.com/th/app/seedkm/id1534522681" style="text-decoration: none; margin: 5px;" >
                    <img alt src="@/assets/Seed_Web_Icons/seed logo.png" width="50" height="50" />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.fusionsol.FusionSeed" style="text-decoration: none; margin: 5px;" >
                    <img alt src="@/assets/Seed_Web_Icons/seed logo.png" width="50" height="50" />
                </a>
            </div>
        </v-container>
    </div>
</template>

<script>
export default {
  data: () => ({
    permissionDialog: false,
    permissionMessage: "",
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    paginationCount: [{ title: 10 }, { title: 20 }, { title: 30 }],
    items: [],
    editData: {},
    dialog: false,
    successDialog: false,
    message: "",
    search: null,
    loading:true,
    Platform: '',
    linkUri: ''
  }),
   mounted() {
    this.Get_URL();
  },
   methods: {

    async getData() {
      let self = this;
      self.loading=true;
      let checkPermission = await self.checkpermission(33);
      if (checkPermission) {
        const res = await self.$axios.post(
          `${self.web_url}FeedBack/GetFeedback?id=` +
            localStorage.getItem("companyID")
        );
        self.items = res.data.data.map((v, i) => ({ ...v, no: i + 1 }));
      } else {
        self.permissionDialog = true;
        self.permissionMessage =
          "You don't have permission to see Feedback Box.";
      }
      self.loading=false;
    },

    Get_URL() {
        let self = this;
        self.userAgent = navigator.userAgent || navigator.vendor;

        if (/android/i.test(self.userAgent)) {
        this.Platform = 'Android';
        }
        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        else if (/iPad|iPhone|iPod/.test(self.userAgent)) {
        this.Platform = 'iOS';
        }
        
        self.myParam = this.$route.query.path;
        switch (self.myParam) {
        case "course":
            this.linkUri = "fusionseed://course";
            break;
        case "landing":
            this.linkUri = "fusionseed://landing";
            break;
        case "library":
            this.linkUri = "fusionseed://library";
            break;
        case "lessonlearn":
            this.linkUri = "fusionseed://lessonlearn";
            break;
        case "event":
            this.linkUri = "fusionseed://event";
            break;
        case "community":
            this.linkUri = "fusionseed://community";
            break;
        default:
            this.linkUri = "fusionseed://" + self.myParam;
            break;
        }
this.RedirectMobileOperatingSystem(this.Platform, this.linkUri)
    },

    RedirectMobileOperatingSystem(Platform, linkUri) {
        let self = this;
        self.deeplink = linkUri;
        if (Platform == 'Android') {
             window.open(self.deeplink, '_self');
        }
        if (Platform == 'iOS') {
             window.open(self.deeplink, '_self');
        }

    },
  }
};
</script>